// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-projects-js": () => import("/opt/build/repo/src/templates/projects.js" /* webpackChunkName: "component---src-templates-projects-js" */),
  "component---src-templates-texts-js": () => import("/opt/build/repo/src/templates/texts.js" /* webpackChunkName: "component---src-templates-texts-js" */),
  "component---src-templates-pages-js": () => import("/opt/build/repo/src/templates/pages.js" /* webpackChunkName: "component---src-templates-pages-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-js": () => import("/opt/build/repo/src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-en-contacts-js": () => import("/opt/build/repo/src/pages/en/contacts.js" /* webpackChunkName: "component---src-pages-en-contacts-js" */),
  "component---src-pages-en-index-js": () => import("/opt/build/repo/src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-texts-js": () => import("/opt/build/repo/src/pages/en/texts.js" /* webpackChunkName: "component---src-pages-en-texts-js" */),
  "component---src-pages-en-thanks-js": () => import("/opt/build/repo/src/pages/en/thanks.js" /* webpackChunkName: "component---src-pages-en-thanks-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-texts-js": () => import("/opt/build/repo/src/pages/texts.js" /* webpackChunkName: "component---src-pages-texts-js" */),
  "component---src-pages-thanks-js": () => import("/opt/build/repo/src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

